<template>
  <div>
    <div class="col-12">
      <div class="card mb-4">
                
      <div id="Logo">
        
        <b-row>
           
        <b-col md="12">
        <dark-Toggler class="d-soon d-lg-block"  style="text-align: right;"  />
          <b-img
              :src="appLogoImage"
              alt="logo"
              width="100%"
              style="margin: 1%;"
            />
           </b-col>
          </b-row>
        
        
      </div>
       

        <div class="card-header">
          <h4 class="card-title">
            <span>Autorização de Entrada : </span>{{autorizacaoEntrada.AUTORIZACAOENTRADAID}} 
          </h4>
        </div>

        <div class="card-body">
          
          <hr>


          <b-row>
           
            <b-col md="9">
              <b-form-group label="Nome Visitante">
                <b-form-input
                    v-model="autorizacaoEntrada.NOMEVISITANTE"
                    disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="RG">
                <b-form-input
                    v-model="autorizacaoEntrada.RG"
                    disabled
                />
              </b-form-group>
            </b-col>

            


          </b-row>



          <b-row>
           
           <b-col md="9">
             <b-form-group label="Email">
               <b-form-input
                   v-model="autorizacaoEntrada.EMAIL"
                   disabled
               />
             </b-form-group>
           </b-col>

           
           <b-col md="3">
             <b-form-group label="Crachá">
               <b-form-input
                   v-model="autorizacaoEntrada.NROCRACHA"
                   disabled
               />
             </b-form-group>
           </b-col>


         </b-row>



         <b-row>
           
           <b-col md="9">
             <b-form-group label="Empresa">
               <b-form-input
                   v-model="autorizacaoEntrada.EMPRESA_VISITANTE"
                   disabled
               />
             </b-form-group>
           </b-col>

           
           <b-col md="3">
             <b-form-group label="CNPJ">
               <b-form-input
                   v-model="autorizacaoEntrada.CNPJ_EMPVISITANTE"
                   disabled
               />
             </b-form-group>
           </b-col>


         </b-row>


         <hr>



         <b-row>
           
           <b-col md="12">
             <b-form-group label="Ira visitar o(a):">
               <b-form-input
                   v-model="autorizacaoEntrada.NOMEFUNC"
                   disabled
               />
             </b-form-group>
           </b-col>

           
           
         </b-row>




         <b-row>
            

            <b-col md="2">
              
              <b-form-group label="Data da Visita">
                <b-form-datepicker
                    v-model="autorizacaoEntrada.DATA"
                    today-button
                    label-today-button="Selecionar Hoje"
                    reset-button
                    label-reset-button="Limpar"
                    placeholder="Selecione"
                    :required="true"
                    dropup
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    disabled
                />
              </b-form-group>

            </b-col>

            <b-col md="2">
              <b-form-group label="Hora Chegada">
                <b-form-input
                    v-model="autorizacaoEntrada.HORAC"
                    disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Hora Saida">
                <b-form-input
                    v-model="autorizacaoEntrada.HORAS"
                    disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Motivo">
                <b-form-input
                    v-model="autorizacaoEntrada.DESCRMOTIVO"
                    disabled
                />
              </b-form-group>
            </b-col>

            
          </b-row>


          <hr>
          <div id="enfermidades" v-if="autorizacaoEntrada.VISITAPRODUTIVO == 'S'">
            

            <b-row>
              
              <b-col md="12">
               <b>Você está portando ou suspeita ter contraído algum tipo das enfermidades listadas abaixo nos últimos 5 dias?</b>
              </b-col>
              <br><br>
            </b-row>
      
            <b-row> 
            
            <b-col md="3">
              <br>
               <b>Fabre: <span class="text-danger">*</span></b>
               <br>
                    <input name="FEBRE" type="radio" id="FEBRESim" value="S" v-model="autorizacaoEntrada.FEBRE" :disabled="PermiteSalvar ? false : ''" > Sim
                    <input name="FEBRE" type="radio" id="FEBRENao" value="N" v-model="autorizacaoEntrada.FEBRE" :disabled="PermiteSalvar ? false : ''" > Não
                
            </b-col>


            <b-col md="3">
              <br>
              <b>Diarréia: <span class="text-danger">*</span></b>
              <br>
                    <input name="DIARREIA" type="radio" id="DIARREIASim" value="S" v-model="autorizacaoEntrada.DIARREIA" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="DIARREIA" type="radio" id="DIARREIANao" value="N" v-model="autorizacaoEntrada.DIARREIA" :disabled="PermiteSalvar ? false : '' " > Não
                
            </b-col>



            <b-col md="3">
              <br>
              <b>Vomito: <span class="text-danger">*</span></b>
              <br>
                    <input name="VOMITO" type="radio" id="VOMITOSim" value="S" v-model="autorizacaoEntrada.VOMITO" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="VOMITO" type="radio" id="VOMITONao" value="N" v-model="autorizacaoEntrada.VOMITO" :disabled="PermiteSalvar ? false : '' " > Não
                
            </b-col>


            </b-row>

            <b-row>


              <b-col md="3">
              <br>
              <b>Inflamações de Garganta: <span class="text-danger">*</span></b>
              <br>
                    <input name="INFGARGANTA" type="radio" id="INFGARGANTASim" value="S" v-model="autorizacaoEntrada.INFGARGANTA" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="INFGARGANTA" type="radio" id="INFGARGANTANao" value="N" v-model="autorizacaoEntrada.INFGARGANTA" :disabled="PermiteSalvar ? false : '' " > Não
                
            </b-col>


            <b-col md="3">
              <br>
              <b>Inflamações no Nariz: <span class="text-danger">*</span></b>
              <br>
                    <input name="INFNARIZ" type="radio" id="INFNARIZSim" value="S" v-model="autorizacaoEntrada.INFNARIZ" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="INFNARIZ" type="radio" id="INFNARIZNao" value="N" v-model="autorizacaoEntrada.INFNARIZ" :disabled="PermiteSalvar ? false : '' " > Não
                
            </b-col>



            <b-col md="3">
              <br>
              <b>Inflamações nos Ouvidos: <span class="text-danger">*</span></b>
              <br>
                    <input name="INFOUVIDOS" type="radio" id="INFOUVIDOSSim" value="S" v-model="autorizacaoEntrada.INFOUVIDOS" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="INFOUVIDOS" type="radio" id="INFOUVIDOSNao" value="N" v-model="autorizacaoEntrada.INFOUVIDOS" :disabled="PermiteSalvar ? false : '' " > Não
                
            </b-col>


            <b-col md="3">
              <br>
              <b>Inflamações nos Olhos: <span class="text-danger">*</span></b>
              <br>
                    <input name="INFOLHOS" type="radio" id="INFOLHOSSim" value="S" v-model="autorizacaoEntrada.INFOLHOS" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="INFOLHOS" type="radio" id="INFOLHOSNao" value="N" v-model="autorizacaoEntrada.INFOLHOS" :disabled="PermiteSalvar ? false : '' " > Não
                
            </b-col>


            </b-row>

            <b-row>

              <b-col md="3">
              <br>
              <b>Doenças de pele: <span class="text-danger">*</span></b>
              <br>
                    <input name="DOENCAPELE" type="radio" id="DOENCAPELESim" value="S" v-model="autorizacaoEntrada.DOENCAPELE" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="DOENCAPELE" type="radio" id="DOENCAPELENao" value="N" v-model="autorizacaoEntrada.DOENCAPELE" :disabled="PermiteSalvar ? false : '' " > Não
                
              </b-col>


              <b-col md="3">
              <br>
              <b>Lesões: <span class="text-danger">*</span></b>
              <br>
                    <input name="LESOES" type="radio" id="LESOESSim" value="S" v-model="autorizacaoEntrada.LESOES" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="LESOES" type="radio" id="LESOESNao" value="N" v-model="autorizacaoEntrada.LESOES" :disabled="PermiteSalvar ? false : '' " > Não
                
              </b-col>

              <b-col md="3">
              <br>
              <b>Queimaduras: <span class="text-danger">*</span></b>
              <br>
                    <input name="QUEIMADURAS" type="radio" id="QUEIMADURASSim" value="S" v-model="autorizacaoEntrada.QUEIMADURAS" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="QUEIMADURAS" type="radio" id="QUEIMADURASNao" value="N" v-model="autorizacaoEntrada.QUEIMADURAS" :disabled="PermiteSalvar ? false : '' " > Não
                
              </b-col>


              <b-col md="3">
              <br>
              <b>Cortes: <span class="text-danger">*</span></b>
              <br>
                    <input name="CORTES" type="radio" id="CORTESSim" value="S" v-model="autorizacaoEntrada.CORTES" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="CORTES" type="radio" id="CORTESNao" value="N" v-model="autorizacaoEntrada.CORTES" :disabled="PermiteSalvar ? false : '' " > Não
                
              </b-col>

            </b-row>

            <b-row>

              <b-col md="12">
              <br>
              
              <b>Contato com pessoas portadoras de enfermidades contagiosas (incluindo COVID-19): <span class="text-danger">*</span></b>
              <br>
                    <input name="ENFCONTAGIOSAS" type="radio" id="ENFCONTAGIOSASSim" value="S" v-model="autorizacaoEntrada.ENFCONTAGIOSAS" :disabled="PermiteSalvar ? false : '' " > Sim
                    <input name="ENFCONTAGIOSAS" type="radio" id="ENFCONTAGIOSASNao" value="N" v-model="autorizacaoEntrada.ENFCONTAGIOSAS" :disabled="PermiteSalvar ? false : '' " > Não
                
              </b-col>

            </b-row>

            <b-row>
              <b-col md="12">
                <br>
              <b-form-group label="Alguma Reação Alérgica?">
              <b-form-textarea
                  id="textarea"
                  v-model="autorizacaoEntrada.ALERGIA"
                  rows="3"
                  max-rows="3"
                  :disabled="PermiteSalvar ? false : true "
              />
              </b-form-group>
              </b-col>


            </b-row>

            <b-row>
              <b-col md="12">
                <br>
              <b-form-group label="Alguma outra enfermidade?">
              <b-form-textarea
                  id="textarea"
                  v-model="autorizacaoEntrada.OUTRAS"
                  rows="3"
                  max-rows="3"
                  :disabled="PermiteSalvar ? false : true "
              />
              </b-form-group>
              </b-col>


            </b-row>



             <hr>
            <b-row>
           
           <b-col md="2">
               Nro do Calçado <span class="text-danger">*</span>
               <b-form-input
                   v-model="autorizacaoEntrada.NROCALCADO"
                   :disabled="PermiteSalvar ? false : '' "
               />
             
           </b-col>

           
           <b-col md="3">
               Tamanho do Uniforme <span class="text-danger">*</span>
               <b-form-select
                    v-model="autorizacaoEntrada.TUNIFORME"
                    :options="optionsTamanhoUniforme"
                      :disabled="PermiteSalvar ? false : '' "
                />

           </b-col>


         </b-row>
            
          </div>


          <hr>

          <b-row>
          <b-col  class="mb-1"  >              
              
              <div class="alert alert-warning" role="alert">
                <!--SE MUDAR DADOS DO TERMO AQUI deve mudar no Email Enviado - AD_PSKY_SP_ENV_EMAIL_AUTORIZACAO_ENTRADA_ACEITE!-->
                    <center><h4 class="alert-heading">TERMO DE SEGURANÇA E CONDUTA</h4></center>
                      <div class="alert-body">
                        • É <b>PROIBIDO</b> o uso de shorts, camisetas sem mangas, chinelos e sandálias;
                        <br>
                        • É <b>PROIBIDO</b> transitar desacompanhado de um colaborador nas dependencias da fábrica;
                        <br>
                        • É <b>OBRIGATÓRIO</b> a assinatura do acampanhante no formulario ao termino da visita;
                        <br>
                        • É <b>OBRIGATÓRIO</b> o uso de sapato fechado;
                        <br>
                        • É <b>PROIBIDA</b> a entrada de menores de idade nas dependências da fábrica;
                        <br>
                        • É <b>PROIBIDO</b> auxiliar na carga e descarga de produtos e subir na carroceria do caminhão;
                        <br>
                        • É <b>PROIBIDO</b> transitar pela fábrica, devendo ficar dentro da cabine do caminhão;
                        <br>
                        • É <b>PROIBIDO</b> a entrada de acompanhantes no caminhão para descarga;
                        <br>
                        • É <b>PROIBIDO</b> lavar o caminhão ou componentes;
                        <br>
                        • É <b>OBRIGATÓRIO</b> transitar ou manobrar com as portas do veículo trancadas e/ou seladas;
                        <br>
                        • <b>NÃO</b> obstrua hidrantes/extintores e a passagem de pedestres e equipamentos;
                        <br>
                        • <b>Deve-se</b> respeitar o limite máximo de velocidade interna de 20 Km/h;
                        <br>
                        • <b>Deve-se</b> ter cuidado ao manobrar os veículos durante a carga/descarga.
                        <br>
                        • Respeite a sinalização;
                        <br>
                        • Em caso de emergência, siga as orientações dos brigadistas.
                      </div>
              </div>
            </b-col>
          </b-row>


          <b-row>
          <b-col  class="mb-1"  >              
            <!--SE MUDAR DADOS DO TERMO AQUI deve mudar no Email Enviado - AD_PSKY_SP_ENV_EMAIL_AUTORIZACAO_ENTRADA_ACEITE!-->
              <div class="alert alert-warning" role="alert">
                    <center><h4 class="alert-heading">TERMO DE BOAS PRÁTICAS DE FABRICAÇÃO</h4></center>
                      <div class="alert-body">
                        • É <b>PROIBIDO</b> entrar nas áreas produtivas sem autorização;
                        <br>
                        • É <b>PROIBIDO</b> fumar em toda unidade fabril;
                        <br>
                        • É <b>PROIBIDA</b> a entrada de qualquer tipo de alimento, incluindo balas, chicletes e bebidas, bem como o consumo fora dos locais permitidos(Refeitório/copa administrativo);
                        <br>
                        • É <b>PROIBIDA</b> a utilização de aparelho celular dentro das áreas produtivas;
                        <br>
                        • Respeite as áreas de acesso restrito;
                        <br>
                        • Quando fizer uso de uniforme, utilizar de maneira correta (não retirar touca e/ou bota) e não sentar em locais inadequados como piso, calçadas,grama;
                        <br>
                        • Se for adentrar em áreas produtivas, não fazer uso de adornos (brincos, anéis, etc).
                      </div>
              </div>
            </b-col>
          </b-row>

          <div class="DivBotaoLadoLado" v-if="PermiteSalvar">
            <b-button
                v-b-toggle="'collapse-filtro'"
                class="mt-3"
                variant="success"
                @click="submit"
            >
              Eu Aceito com os Termos
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import 'bootstrap-icons/font/bootstrap-icons.css'
import {
  BBadge,
  BButton,
  BCol,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BRow,
  BTable,
  VBToggle,
  BImg,
} from 'bootstrap-vue'
import { maska } from 'maska'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import apiPortalSky from '@/services/apiPortalSky'
import Spinner from '@/components/Spinner.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

export default {
  components: {
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BBadge,
    DarkToggler,
    BImg,
  },
  directives: {
    'b-toggle': VBToggle,
    maska,
  },
  props: ['AUTORIZACAOENTRADAID', 'edicaoAdm'],
  data() {
    return {
      // MASCARAS
      maskCNPJ: '##.###.###/####-##',

      // VARIAVEIS
      autorizacaoEntrada: {
      },
      optionsTamanhoUniforme: [
        {
          value: null,
          text: 'Selecione uma opção',
          disabled: true,
        },
        {
          value: 'P',
          text: 'P',
        },
        {
          value: 'M',
          text: 'M',
        },
        {
          value: 'G',
          text: 'G',
        },
        {
          value: 'GG',
          text: 'GG',
        },
        {
          value: 'EG',
          text: 'EG',
        },
      ],
      appLogoImage:'',
      PermiteSalvar: true,
    }
  },
  
  async mounted() {
    

    this.appLogoImage = require('@/assets/images/logo/LogoVapza.png')

    await this.mostrarDetalhe() 
  },
  methods: {

    // MÉTODOS DE REQUISIÇÕES ---------------------------------------------------------------------

    async submit() {

      this.Alterar()

    },

    Alterar() {
      Spinner.StarLoad()

    // Verifica se todos os campos preenchido
    if(this.autorizacaoEntrada.VISITAPRODUTIVO == 'S')    {
       if (!this.validarCamposObrigatorios()) {
         AlertaMsg.FormataMsg({
           alert_type: 'error',
           message: 'Por favor, preencha todos os campos obrigatórios. *',
         });
   
         return;
       }
    }


    const usuario = this.$session.get('usuario')
    try {
      
      this.autorizacaoEntrada.CODUSU =  usuario.codusu 
      this.autorizacaoEntrada.LOGACEITE = JSON.stringify(UtilVUE.InformacoesNavegador());      
      
      const URL = this.autorizacaoEntrada.AUTORIZACAOENTRADAID && this.autorizacaoEntrada.AUTORIZACAOENTRADAID !== 0 ? '/AutorizacaoEntrada/Alterar' : '/AutorizacaoEntrada/Inserir'
      const ParametroJson = JSON.parse(JSON.stringify(this.autorizacaoEntrada))

      apiPortalSky.post(URL, ParametroJson)
        .then(response => {
          
          if (response.status === 200) {

            this.PermiteSalvar = false

            AlertaMsg.FormataMsg({
              title: 'Aceite de Termos',
              alert_type: 'info',
              message: 'Realizado com Sucesso',
            })
            this.$router.push({ name: 'MensagemOut' })
          }
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao realizar o Aceite',
          })
        })
      } catch (error) {
          AlertaMsg.FormataMsg({
             alert_type: 'error',
             message: error,
           });
        }
        
    },
    async mostrarDetalhe() {
      Spinner.StarLoad()

      const URL = `/AutorizacaoEntrada/Mostra?AUTORIZACAOENTRADAID=${this.AUTORIZACAOENTRADAID}`
      
      apiPortalSky.post(URL)
        .then(response => {
          if (response.status === 200) {

           //Armazena Dados do Agendamento
           this.autorizacaoEntrada = response.data[0]
           

           if(this.autorizacaoEntrada.DHACEITETERMO != null){
            this.PermiteSalvar = false
           }

            Spinner.EndLoad()
          } else {
            AlertaMsg.FormataMsg({
              alert_type: 'attention',
              message: 'Autorização de Entrada não Localizado. Acesse novamente pelo Link enviado.',
            })

            this.$router.push({ name: 'MensagemOut' })
          }
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao buscar dados da Autorização de Entrada. Acesse novamente pelo Link enviado.',
          })

           this.$router.push({ name: 'MensagemOut' })
        })

    },


    // Método para verificar se todos os campos foram preenchidos
    validarCamposObrigatorios() {
    const camposObrigatorios = [
      'FEBRE', 'DIARREIA', 'VOMITO', 'INFGARGANTA', 'INFNARIZ', 
      'INFOUVIDOS', 'INFOLHOS', 'DOENCAPELE', 'LESOES', 'QUEIMADURAS',
      'CORTES', 'ENFCONTAGIOSAS','NROCALCADO','TUNIFORME'
    ];
    
    return camposObrigatorios.every(campo => !!this.autorizacaoEntrada[campo]);
  },
   

  },
}
</script>

<style>
.mt-18 {
  margin-top: 1.8rem;
}
</style>
